import React, { useMemo, useState } from 'react';
import { sumBy } from 'lodash';
import { PageErrors } from '@/components/Page/Errors';
import { School, Purchase, User, Coupon, SchoolId, PaymentMethod } from '@/types';
import { StripeForm } from '@/components/Stripe/Form';
import { BillingSection } from '@/components/Billing/Section';
import { CrosspostSelector } from '@/components/Crosspost/Selector';
import { CouponInput } from '@/components/Coupon/Input';
import { BillingReceipt, BillingReceiptItem } from '@/components/Billing/Receipt';

interface PurchaseNewPageProps {
  errors: any[];
  user: User;
  payment_methods: PaymentMethod[];
  current_school: School;
  nearby_schools: School[];
  purchase: Purchase;
  school_ids: SchoolId[];
  endpoint: string;
  coupon_endpoint: string;
  action_type: string;
}

function PurchasesNewPage({
  errors,
  user,
  payment_methods,
  current_school,
  nearby_schools,
  purchase,
  school_ids,
  endpoint,
  coupon_endpoint,
  action_type,
}: PurchaseNewPageProps) {
  const [crosspostSchoolIds, setCrosspostSchoolIds] = useState(school_ids);
  const [coupon, setCoupon] = useState<Coupon>();

  const billingItems: BillingReceiptItem[] = useMemo(() => {
    const currentSchoolItem = { name: current_school.name, amountCents: purchase.original_listing_price_cents };

    const crosspostItems = nearby_schools
      .filter((school) => crosspostSchoolIds.includes(school.id))
      .map((school) => ({
        name: school.name,
        amountCents: purchase.price_cents_per_crosspost,
      }));

    const crosspostDiscount = crosspostItems
      .slice(0, purchase.subscription_additional_crossposts)
      .map((item) => item.amountCents).reduce((a, b) => a + b, 0);

    const crosspostDiscountItem =
      crosspostDiscount > 0
        ? { name: 'Crosspost Discount', amountCents: -crosspostDiscount }
        : undefined;

    const couponItem = coupon ? { name: 'Coupon', amountCents: -coupon.amount_cents } : undefined;

    const platformDiscountItem =
      purchase.platform_discount_cents > 0
        ? { name: 'Discount', amountCents: -purchase.platform_discount_cents }
        : undefined;

    return [currentSchoolItem, ...crosspostItems, couponItem, crosspostDiscountItem, platformDiscountItem].filter(
      (item) => item
    ) as BillingReceiptItem[];
  }, [current_school, purchase, coupon, nearby_schools, crosspostSchoolIds]);

  const totalAmountCents = useMemo(() => {
    const sum = sumBy(billingItems, (billingItem) => billingItem.amountCents);
    return Math.max(sum, 0);
  }, [billingItems]);

  return (
    <div>
      <PageErrors errors={errors} />
      <div className="row">
        <div className="col-md">
          <BillingSection title="Crosspost at nearby universities?">
            <p>You're already posting at <b>{current_school.name}</b>. Would you like to additionally post your listing at any of these school portals?</p>
            <CrosspostSelector
              schools={nearby_schools}
              selectedSchoolIds={crosspostSchoolIds}
              priceCentsPerCrosspost={purchase.price_cents_per_crosspost}
              onChange={setCrosspostSchoolIds}
            />
          </BillingSection>
        </div>
        <div className="col-md">
          <BillingSection title="Your total">
            <BillingReceipt items={billingItems} totalAmountCents={totalAmountCents} />
            <footer className="text-right">For {purchase.posting_length_days} days</footer>
          </BillingSection>

          <BillingSection title={totalAmountCents === 0 ? undefined : 'Payment method'}>
            <StripeForm
              user={user}
              payment_methods={payment_methods}
              endpoint={endpoint}
              amountCents={totalAmountCents}
              purchaseData={{ school_ids: crosspostSchoolIds, coupon_code: coupon?.code, action_type: action_type }}
            />
          </BillingSection>


          <BillingSection title="Have a coupon code?">
            <CouponInput endpoint={coupon_endpoint} onChange={setCoupon} />
          </BillingSection>

          <BillingSection title="Problems? Questions?">
            <p>Call us at (855) 633-2267 or email us at help@jumpoffcampus.com</p>
          </BillingSection>
        </div>
      </div>
    </div>
  );
}

export default PurchasesNewPage;
