import React, { Component } from 'react';
import { Widget } from '@uploadcare/react-widget';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const MAX_IMAGES_AMOUNT = 100;
const API_KEY = window.ENV.UPLOADCARE_PUBLIC_KEY;

/*
 * Helper function for DND interface, to return the newly sorted list.
 */
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface ImageUploaderImage {
  id: string;
  rank: number;
}

interface ImageUploaderProps {
  images?: ImageUploaderImage[];
  imageableId: string;
  imageableType: string;
}

interface ImageUploaderState {
  images: ImageUploaderImage[];
}

export class ImageUploader extends Component<ImageUploaderProps, ImageUploaderState> {
  constructor(props: ImageUploaderProps) {
    super(props);
    this.state = { images: this.props.images || [] };
  }

  postPhotos = async (uuid: string, imageableId: string, imageableType: string) => {
    try {
      const base_url = `https://upload.uploadcare.com/group/info/?pub_key=${API_KEY}&group_id=${uuid}`;

      // Get json of all files uploaded
      const response = await axios.get(base_url);
      const imgFiles: any[] = response.data.files.slice(0, MAX_IMAGES_AMOUNT);

      let uuids = imgFiles.map((img) => img.uuid);

      // Post to our db
      const fileResponse = await axios.post(`/images`, {
        imageable_type: `${imageableType}`,
        imageable_id: `${imageableId}`,
        uuids: uuids,
      });

      this.setState({ images: [...this.state.images, ...fileResponse.data] });
    } catch (err) {
      alert(
        'Sorry, there was a problem uploading your images.\nPlease try refreshing the page.\nIf you are still having trouble, please contact our customer support.'
      );
    }
  };

  uploadFinished = (info: any) => {
    this.postPhotos(info.uuid, this.props.imageableId, this.props.imageableType);
    // if the Wedget is forced to re-render at any point, ti's onChange stops firing entirely.
    // our silly solution is to prevent the Wedget from ever re-rendering, and use this jenky
    // jQuery to clear it each time uploads finish
    // @ts-ignore
    $('.uploadcare--widget__button.uploadcare--widget__button_type_remove').click();
  };

  /*
   * Creates the url for new images or modifies legacy url to display at correct size.
   */
  imageUrl = (image: any) => {
    return image.legacy_url === null
      ? `https://ucarecdn.com/${image.uuid}/-/resize/x200/`
      : image.legacy_url;
  };

  /*
   * Updates the image rank on PSQL side.
   */
  updateImageRank = async (image: any) => {
    try {
      await axios.patch(`/images/${image.id}/`, { rank: image.rank });
      return true;
    } catch (err) {
      alert(
        'Sorry, there was a problem updating the rank of your image!\nPlease try refreshing the page.\nIf you are still having trouble, please contact our customer support.'
      );
    }
    return false;
  };

  /*
   * Executes after dragging action is finished -- updates the ranks of the images.
   */
  onDragEnd = (result: any) => {
    // item was dropped outside the list
    if (!result.destination) {
      return;
    }

    let reorderedImages = reorder(this.state.images, result.source.index, result.destination.index).map(
      (img, index) => {
        if (img.rank !== index) {
          img.rank = index;
          this.updateImageRank(img);
        }
        return img;
      }
    );

    this.setState({ images: reorderedImages });
  };

  deleteImage = async (image: any) => {
    try {
      let deleteResp = await axios.delete(`/images/${image.id}`, {});
      let updatedRanks = this.state.images.map((img) => {
        if (img.rank > image.rank) {
          img.rank -= 1;
          this.updateImageRank(img);
        }
        return img;
      });
      // reset state to the list of images after one deletion with all updated ranks
      this.setState({
        ...this.state,
        images: updatedRanks.filter((image) => deleteResp.data.id !== image.id),
      });
    } catch (err) {
      alert(
        'Sorry, there was a problem deleting your image!\nPlease try refreshing the page.\nIf you are still having trouble, please contact our customer support.'
      );
    }
  };

  render() {
    let sortedImages = this.state.images.sort((a, b) => a.rank - b.rank);

    return (
      <div id={'main-content'} className={'row'}>
        <div id={'images-upload'} className={'col'}>
          {this.state.images.length < MAX_IMAGES_AMOUNT ? (
            <Widget
              publicKey={API_KEY}
              multiple={true}
              multipleMax={MAX_IMAGES_AMOUNT - this.state.images.length}
              clearable
              onChange={this.uploadFinished}
            />
          ) : (
            <p className="alert alert-warning">
              Delete some images if you want to add more!
              <br />
              Max {MAX_IMAGES_AMOUNT} images.
            </p>
          )}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sortedImages.map((img, index) => (
                    <Draggable key={img.id} draggableId={img.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="uploaded-img"
                        >
                          <img src={this.imageUrl(img)} alt="image" style={{ height: '100px' }} />
                          <a className="btn" onClick={() => this.deleteImage(img)}>
                            <i className="fa fa-2x fa-trash"></i>
                          </a>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}
